import { MentorSessionQueryResult } from "entities/MentorSession";
import MentorSessionCard from "./MentorSessionCard";
import styles from "./MentorSessions.module.css";
import useAcceptSession from "hooks/mentor/session/useAcceptSession";
import useDeclineSession from "hooks/mentor/session/useDeclineSession";
import TimeSlotSelector from "../../Molecules/TimeSlotSelector/TimeSlotSelector";
import { ThreeDots } from "react-loader-spinner";
import useMyProfile from "hooks/useMyProfile";
interface RequestedSessionProps {
  session: MentorSessionQueryResult;
}

const RequestedSession = (props: RequestedSessionProps) => {
  const { accept, is_loading: accept_session_loading } = useAcceptSession();
  const { decline, is_loading: decline_session_loading } = useDeclineSession();
  const { profile } = useMyProfile();

  const handleAcceptSession = async (slot: string): Promise<void> => {
    accept(props.session._id, slot, () => {
      window.location.reload();
    });
  };

  const handleDeclineSession = async () => {
    decline(props.session._id, "", () => {
      window.location.reload();
    });
  }

  const handleRescheduleSession = async () => {
    window.open(`mailto:team@mentorpass.co?subject=Reschedule session&body=Hey team, I'd like to reschedule my session`, '_blank');
  }

  return (
    <MentorSessionCard
      session={props.session}
      actions={
        <div className={styles.requested_session_action_container}>
          <TimeSlotSelector
            timezone={profile.timezone}
            inital_slot={props.session.primary_request_slot}
            slots={props.session.mentee_request_slots}
            note={props.session.reschedule_note}
            accept_session_loading={accept_session_loading}
            confirmSession={handleAcceptSession}
            decline_session_loading={decline_session_loading}
            handleRescheduleSession={handleRescheduleSession}
            handleDeclineSession={handleDeclineSession}
          />
        </div>
      }
    />
  );
};

export default RequestedSession;
