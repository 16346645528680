import { createMachine, assign, ServiceMap } from "xstate";

interface ObjectiveContext {
  objective_id?: string;
}

type ObjectiveEvent =
  | {
      type: "CREATE";
    }
  | {
      type: "EDIT";
      data: {
        objective_id: string;
      };
    }
  | {
      type: "VIEW";
      data: {
        objective_id: string;
      };
    }
  | {
      type: "CLOSE";
    }
  | {
      type: "SAVED";
      data: {
        objective_id: string;
      };
    }
  | {
      type: "UPDATE";
      data: {
        objective_id: string;
      };
    };

const ObjectiveMachine = createMachine({
  /** @xstate-layout N4IgpgJg5mDOIC5QHsBGArMBjALgSwDcwB9AvMAdwDosAbZWSAYgGEAlAUQEEAVDgbQAMAXUSgADgzz5kAOzEgAHogCMAdgCcVAEwAOFdoBsAVm3Hda3RsGGANCACeiAMwqtG7RsMXjAX1-2aJi4hCRklDT0jBBMHAAiAJI8QqJIIJKw0nhyCsoIKsZaegYmZhZWNvZOCIYazlS6zoWmauaWGir+gRjY+ESk5NR0DMwAagkcAOopChlZOWl5KrU6+kambRV2joiFag262ppq2gAsBiqCGl0gQb2hAxHI4mCyVJDSrAAyAPIAygIRLMpDJ5ItVKcimtSptrNtqhpTqcqLUjoJGs5MVibncQv1wtRnq93hBPn8uKN4jM0nNQblEKdtIIqIyVBUyu1KjsEGpzlQmnVWjieniwoMqES3gTvv9AakJCDsmDQEsrKsShtynCqoh9FQ-AFbiK+mKni83rJKDKAdSFZk6eD8mriusOVsdfk1PtTsZXGZhcETY9CeaqJaKKxOLwqUCaYqFiqGUyWdo2dY3druT7kacbGcNAXCwXTv5DbJkBA4ApcUGCcD7Ur6QgALTwxCtgP3fHi4bRevzZVKJMelRsqijxFuQyM3TGNQqEuGmsPAkS839h2JhB6fazurp2Fc6rOQyGKhw-NFwuL7qBlfiyUk6QbxuOzHGBqFZwHrVH3XGM83DRDEsUxTtRWDNdiTrOMGwTIcEGMUwGlaQQCgzFQPUMVNxyuZwhSXY17zNYlwxfeC8nfT990EDM-wQU55x0ZYF3MUDnCMUtfCAA */
  id: "objective_view",
  initial: "closed",
  schema: {
    context: {} as ObjectiveContext,
    events: {} as ObjectiveEvent,
  },
  states: {
    closed: {
      on: {
        CREATE: {
          target: "open.new",
          actions: assign(() => ({
            objective_id: undefined,
          })),
        },
        EDIT: {
          target: "open.edit",
          actions: assign((_, event) => ({
            objective_id: event.data.objective_id,
          })),
        },
        VIEW: {
          target: "open.view",
          actions: assign((_, event) => ({
            objective_id: event.data.objective_id,
          })),
        },
      },
    },
    open: {
      states: {
        edit: {
          on: {
            CLOSE: {
              target: "#objective_view.closed",
            },
            SAVED: {
              target: "view",
            },
          },
        },
        view: {
          on: {
            CLOSE: {
              target: "#objective_view.closed",
            },
            EDIT: {
              target: "edit",
            },
          },
        },
        new: {
          on: {
            CLOSE: {
              target: "#objective_view.closed",
            },
            SAVED: {
              target: "view",
            },
          },
        },
      },
    },
  },
  predictableActionArguments: true,
});

export default ObjectiveMachine;
