import rootReducer from "./reducers";
import * as Sentry from "@sentry/react";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import LogRocket from "logrocket";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

// RTK APIs
import profileAPI from "./dux/profiles/api";

import UIMiddleware from "./dux/ui/ui.middleware";

export const store = configureStore({
  reducer: rootReducer,
  middleware: (gDM) =>
    gDM({ immutableCheck: true }).concat(
      profileAPI.middleware,
      UIMiddleware,
      LogRocket.reduxMiddleware()
    ),
  enhancers: [sentryReduxEnhancer],
  devTools: true,
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
